import lamboImage from '../../assets/images/Lambo.webp';
import lamboImageLarge from '../../assets/images/LamboLarge.webp';
import styles from './HowItWorks.module.css';
import { getCopy } from './HowItWorks.copy';
import { useLanguage } from 'contexts/LanguageContext';

const HowItWorks = () => {
    const [language] = useLanguage();
    const { stages, body, subTitle } = getCopy(language);

    return (
        <div className={styles.container}>
            <div className={styles.howItWorksContainer}>
                <img
                    src={lamboImageLarge}
                    alt="Shakira with her lamborghini, the grand prize"
                    className={styles.lamboImage}
                    srcSet={`${lamboImage} 858w, ${lamboImageLarge} 1716w`}
                    sizes={'(min-width: 768px) 1716px, 858px'}
                    loading="lazy"
                />

                <div>
                    <p className={styles.subTitle}>
                        <strong>{subTitle}</strong>
                    </p>

                    <ol className={styles.stageList}>
                        {stages.map((stage, index) => (
                            <li key={index} className={styles.stage}>
                                <span className={styles.number}>{`0${index + 1}`}</span>
                                <span>{stage}</span>
                            </li>
                        ))}
                    </ol>
                </div>
            </div>

            <div className={styles.copyContainer}>
                {body.map((part, index) => (
                    <p key={index}>{part}</p>
                ))}
            </div>
        </div>
    );
};

export default HowItWorks;
