import PostsGrid from '../components/PostsGrid/PostsGrid';
import VideoPost, { Post } from '../components/VideoPost/VideoPost';
import useSinglePlayback from '../hooks/useSinglePlayback';
import styles from './VotePosts.module.css';
import { useEffect, useState } from 'react';
import cn from 'classnames';
import useVoteLink from './useVoteLink';
import VoteLinkError from './VoteLinkError/VoteLinkError';
import Button from 'ui/Button/Button';
import { datadogLogs } from '@datadog/browser-logs';
import ErrorCard from 'ui/Forms/ErrorCard/ErrorCard';
import getCopy from './VotePosts.copy';
import { useLanguage } from 'contexts/LanguageContext';
import Card from 'ui/Card/Card';
import { ReactComponent as CheckCircle } from 'assets/icons/CheckCircle.svg';
import useCountdown from './useCountdown';
import { getImageUrl } from '../hooks/useFeedPosts';

export type FinalistPost = Post & {
    feed_post_id: number;
};

const getDynamicImage = (videoId: string) =>
    `https://customer-75ag3g3ayxpvur4a.cloudflarestream.com/${videoId}/thumbnails/thumbnail.gif?duration=1s`;

// This is probably going to be an array with proper ids and configs
// similar to static feed posts array
export const finalistPosts: FinalistPost[] = [
    {
        platform: 'instagram',
        post_url: 'https://www.instagram.com/reel/DDCa-9NusLi/',
        feed_post_id: 184,
        cloudflare_video_id: '4fd55a1e7f6fd263d28a58e5ae792f2d',
        thumbnail: getImageUrl('9898ec74-05a4-41f8-6b01-17c5867d4400'),
        thumbnailLarge: getImageUrl('9898ec74-05a4-41f8-6b01-17c5867d4400', 'feedPostCover2x'),
        dynamic_thumbnail: getDynamicImage('4fd55a1e7f6fd263d28a58e5ae792f2d'),
        account_handle: 'michaelmejiaart',
        view_count: null,
    },
    {
        platform: 'tiktok',
        post_url: 'https://www.tiktok.com/@angellopezofficial/video/7443156365595430200',
        feed_post_id: 183,
        cloudflare_video_id: '9683e2311d9af0a97f9eb8aacee0a3e3',
        thumbnail: getImageUrl('1cc1bc4c-062b-4ed5-8c7c-55771480d300'),
        thumbnailLarge: getImageUrl('1cc1bc4c-062b-4ed5-8c7c-55771480d300', 'feedPostCover2x'),
        dynamic_thumbnail: getDynamicImage('9683e2311d9af0a97f9eb8aacee0a3e3'),
        account_handle: 'angellopezofficial',
        view_count: null,
    },
    {
        platform: 'tiktok',
        post_url: 'https://www.tiktok.com/@teamnucitaofficial/video/7442485945649204511',
        feed_post_id: 182,
        cloudflare_video_id: '4936cbcf89f2c2e4ee2b31dc4d1cbb0d',
        thumbnail: getImageUrl('3ec64431-ec8c-428c-e4d4-dd5a8c083300'),
        thumbnailLarge: getImageUrl('3ec64431-ec8c-428c-e4d4-dd5a8c083300', 'feedPostCover2x'),
        dynamic_thumbnail: getDynamicImage('4936cbcf89f2c2e4ee2b31dc4d1cbb0d'),
        account_handle: 'teamnucitaofficial',
        view_count: null,
    },
    {
        platform: 'instagram',
        post_url: 'https://www.instagram.com/reel/DCviJM0uXmQ/',
        feed_post_id: 181,
        cloudflare_video_id: '3e0fa73e3bf84574e9e4855a82ae9ad9',
        thumbnail: getImageUrl('28cd7f81-68c7-44db-d634-d101e1199900'),
        thumbnailLarge: getImageUrl('28cd7f81-68c7-44db-d634-d101e1199900', 'feedPostCover2x'),
        dynamic_thumbnail: getDynamicImage('3e0fa73e3bf84574e9e4855a82ae9ad9'),
        account_handle: 'janethgonzalezviolin',
        view_count: null,
    },
    {
        platform: 'tiktok',
        post_url: 'https://www.tiktok.com/@brunamai11/video/7441928070203804983',
        feed_post_id: 180,
        cloudflare_video_id: '535876c507b8d589b6311fe0975d6637',
        thumbnail: getImageUrl('ce3bab85-6295-4213-5e38-34510db4cb00'),
        thumbnailLarge: getImageUrl('ce3bab85-6295-4213-5e38-34510db4cb00', 'feedPostCover2x'),
        dynamic_thumbnail: getDynamicImage('535876c507b8d589b6311fe0975d6637'),
        account_handle: 'brunamai11',
        view_count: null,
    },
];

type Props = {
    voteLinkUUID: string | undefined;
};

const VotePosts = ({ voteLinkUUID }: Props) => {
    const handleSinglePlayback = useSinglePlayback();
    const [language] = useLanguage();
    const copy = getCopy(language);

    const [{ status, data }, castVote] = useVoteLink(voteLinkUUID);
    const timeLeft = useCountdown();

    const [selectedPost, setSelectedPost] = useState<number | null>(null);
    const [isVoteSubmitting, setIsVoteSubmitting] = useState(false);
    const [voteCastingError, setVoteCastingError] = useState(false);

    const isCastingDisabled =
        selectedPost === null || status !== 'success' || data?.feed_post_id === selectedPost || timeLeft <= 0;

    useEffect(() => {
        if (status === 'success') {
            setSelectedPost(data?.feed_post_id ?? null);
        }
    }, [status, data?.feed_post_id]);

    const handleVoteCast = async () => {
        if (!selectedPost || isVoteSubmitting) {
            return;
        }

        setIsVoteSubmitting(true);
        try {
            const response = await castVote(selectedPost);
            if (response?.status === 200) {
                setSelectedPost(response.data.feed_post_id);
                return;
            }

            setVoteCastingError(true);
        } catch (e) {
            datadogLogs.logger.error(
                'user could not cast a vote',
                { voteLink: voteLinkUUID },
                e instanceof Error ? e : undefined
            );

            setVoteCastingError(true);
        } finally {
            setIsVoteSubmitting(false);
        }
    };

    if (status === 'loading' || status === 'idle') {
        return null;
    }

    if (status === 'error') {
        return <VoteLinkError />;
    }

    return (
        <article className={styles.container}>
            <PostsGrid className={styles.grid}>
                {finalistPosts.map((post) => (
                    <VideoPost
                        key={post.cloudflare_video_id}
                        isGestureDisabled
                        shouldPersistPlayButton
                        className={cn({ [styles.selected]: selectedPost === post.feed_post_id })}
                        post={post}
                        onPlay={handleSinglePlayback}
                        onClick={() => {
                            if (data?.feed_post_id) {
                                return;
                            }

                            setSelectedPost(post.feed_post_id);
                            setVoteCastingError(false);
                        }}
                    />
                ))}
            </PostsGrid>

            {status === 'success' && data && !data.feed_post_id && (
                <Button
                    appearance="primary"
                    isPending={isVoteSubmitting}
                    isDisabled={isCastingDisabled}
                    onPress={handleVoteCast}
                >
                    {copy.voteButtonLabel}
                </Button>
            )}

            {status === 'success' && data && data.feed_post_id && (
                <Card className={styles.successContainer}>
                    <p>
                        {copy.thankYouForVoting}
                        <br />
                        {copy.winnerWillBeAnnounced}
                    </p>
                    <CheckCircle className={styles.successIcon} />
                </Card>
            )}

            {voteCastingError && (
                <ErrorCard>
                    {language === 'en' ? 'Could not register a vote' : 'No se pudo registrar el voto'}
                </ErrorCard>
            )}
        </article>
    );
};

export default VotePosts;
