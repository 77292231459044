import Disclosure from 'ui/Disclosure/Disclosure';
import styles from './Faqs.module.css';
import { useLanguage } from 'contexts/LanguageContext';
import { getFaqsCopy } from './Faqs.copy';

const Faqs = () => {
    const [language] = useLanguage();
    return (
        <dl className={styles.container}>
            {getFaqsCopy(language).map((faq) => (
                <Disclosure key={faq.question}>
                    <div className={styles.entry}>
                        <dt>
                            <Disclosure.Trigger>{faq.question}</Disclosure.Trigger>
                        </dt>
                        <dd className={styles.description}>
                            <Disclosure.Content>{faq.answer()}</Disclosure.Content>
                        </dd>
                    </div>
                </Disclosure>
            ))}
        </dl>
    );
};

export default Faqs;
