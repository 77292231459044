import { GenericDropdownOption } from 'App.types';
import { useLanguagePreferenceCookie } from 'hooks/useLanguageCookie';
import React, { createContext, useState, useContext, ReactNode } from 'react';

export type Language = 'en' | 'es';
const supportedLanguages: Language[] = ['en', 'es'];

// user languages may be in form of en-US and etc
const languages = window.navigator.languages;
let preferredLanguage: Language | undefined;

languages.forEach((userLang) => {
    const lang = supportedLanguages.find((lang) => userLang.startsWith(lang));
    // as soon as we find first user preferred language, do nothing.
    if (preferredLanguage) {
        return;
    }

    preferredLanguage = lang;
});

type LanguageContextProps = [Language, (l: Language) => void];

const LanguageContext = createContext<LanguageContextProps | undefined>(undefined);

export const LanguageProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [languagePreferenceCookie, setLanguagePreferenceCookieWithConsent] = useLanguagePreferenceCookie();

    const [language, setLanguage] = useState<Language>((languagePreferenceCookie || preferredLanguage) ?? 'en');

    const handleSetLanguage = (value: Language) => {
        setLanguage(value);
        setLanguagePreferenceCookieWithConsent(value);
    };

    return <LanguageContext.Provider value={[language, handleSetLanguage]}>{children}</LanguageContext.Provider>;
};

export const useLanguage = (): LanguageContextProps => {
    const context = useContext(LanguageContext);
    if (!context) {
        throw new Error('useLanguage must be used within a LanguageProvider');
    }
    return context;
};

export const languageOptions: GenericDropdownOption<Language>[] = [
    { value: 'en', label: 'en' },
    { value: 'es', label: 'es' },
];
