import { HTMLProps } from 'react';
import cn from 'classnames';
import styles from './Card.module.css';

type Props = HTMLProps<HTMLDivElement>;

const Card = ({ className, ...props }: Props) => {
    return <div {...props} className={cn(styles.card, className)} />;
};

export default Card;
