import { DetailedHTMLProps, HTMLAttributes } from 'react';
import Content from 'ui/Layout/Content/Content';
import styles from './FeedPostsHomePageSection.module.css';
import PostsGrid from '../components/PostsGrid/PostsGrid';
// import useFeedPosts from '../hooks/useFeedPosts';
// import { SOLTERA_TIKTOK_AUDIO_LINK } from 'constants/links';
import cn from 'classnames';
// import Button from 'ui/Button/Button';
// import { openInNewTab } from '@round/utils';
// import { useGetScreenDimensions } from 'hooks/useGetScreenDimensions';
// import { TABLET_WIDTH, DESKTOP_WIDTH } from 'constants/breakpoints';
// import random from 'lodash/random';
import VideoPost, { Post } from '../components/VideoPost/VideoPost';
import useSinglePlayback from '../hooks/useSinglePlayback';
// import { Language, useLanguage } from 'contexts/LanguageContext';
import { finalistPosts, FinalistPost } from 'Modules/Posts/VotePosts/VotePosts';
import { shouldAnnounceWinner } from 'utility/utility';

// const viewMoreButtonCopyMap: Record<Language, (b: boolean) => string> = {
//     en: (shouldLinkToPlatform: boolean) => (shouldLinkToPlatform ? 'View All' : 'View More'),
//     es: (shouldLinkToPlatform: boolean) => (shouldLinkToPlatform ? 'Ver Todo' : 'Ver Más'),
// };

type Props = DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>;
// const SEED = random(0, 16).toString(16);

const isWinnerPost = (p: Post | FinalistPost) => {
    const WINNER_FEED_POST_ID = 184;
    return (p as FinalistPost)?.feed_post_id === WINNER_FEED_POST_ID;
};

const FeedPostsHomePageSection = (props: Props) => {
    // const { screenWidth } = useGetScreenDimensions();
    // const pageSize = screenWidth >= DESKTOP_WIDTH ? finalistPosts.length : screenWidth >= TABLET_WIDTH ? 9 : 6;
    // since on different devices we display different number of posts
    // this state keeps track of the page number to slice static data
    // to imitate pagination.
    // const [page, setPage] = useState(1);

    // const { fetchData, data, status } = useFeedPosts();
    // const fetchedPosts =
    //     data?.data.map(
    //         (p): Post => ({
    //             ...p,
    //             // values are taken from the post rect with aspect ratio taken into account
    //             thumbnail: `${p.thumbnail}?width=220&height=392`,
    //             thumbnailLarge: `${p.thumbnail}?width=440&height=782`,
    //             dynamic_thumbnail: `${p.dynamic_thumbnail}&width=220&height=392`,
    //         })
    //     ) ?? [];

    const posts = finalistPosts;
    // const shouldLeadToTikTok = (data?.lastFetchedPage ?? 0) >= 3 || (status === 'success' && !data?.hasNextPage);

    const handleSinglePlayback = useSinglePlayback();

    // const [language] = useLanguage();
    // const viewMoreButtonCopy = viewMoreButtonCopyMap[language](shouldLeadToTikTok);

    return (
        <section {...props} className={cn(styles.section, props.className)}>
            <Content className={styles.content}>
                <PostsGrid>
                    {posts.map((post) => (
                        <VideoPost
                            key={post.post_url}
                            isWinner={isWinnerPost(post) && shouldAnnounceWinner()}
                            post={post}
                            onPlay={handleSinglePlayback}
                        />
                    ))}
                </PostsGrid>
            </Content>

            {/*<Button*/}
            {/*    appearance="primary"*/}
            {/*    className={styles.viewMoreButton}*/}
            {/*    isPending={status === 'loading'}*/}
            {/*    onPress={() => {*/}
            {/*        if (shouldLeadToTikTok) {*/}
            {/*            openInNewTab(SOLTERA_TIKTOK_AUDIO_LINK);*/}
            {/*            return;*/}
            {/*        }*/}

            {/*        if (page * pageSize < finalistPosts.length) {*/}
            {/*            setPage((p) => p + 1);*/}
            {/*            return;*/}
            {/*        }*/}

            {/*        if (status === 'idle' || (status === 'success' && data?.hasNextPage) || status === 'error') {*/}
            {/*            fetchData({*/}
            {/*                page: (data?.lastFetchedPage ?? 0) + 1,*/}
            {/*                page_size: pageSize,*/}
            {/*                ordering: 'random',*/}
            {/*                seed: SEED,*/}
            {/*            }).catch(() => {*/}
            {/*                openInNewTab(SOLTERA_TIKTOK_AUDIO_LINK);*/}
            {/*            });*/}
            {/*        }*/}
            {/*    }}*/}
            {/*>*/}
            {/*    {viewMoreButtonCopy}*/}
            {/*</Button>*/}

            {/* background shades */}
            <div className={styles.blurEffect} aria-hidden>
                <div className={styles.blob1} />
                <div className={styles.blob2} />
                <div className={styles.blob3} />
            </div>

            <div className={styles.topShade} aria-hidden />
            {/*<div className={styles.bottomShade} aria-hidden />*/}
        </section>
    );
};

export default FeedPostsHomePageSection;
