import { Language } from 'contexts/LanguageContext';
import { useCookies } from 'react-cookie';
import { useConsentCookie } from './useConsentCookie';

export function useLanguagePreferenceCookie() {
    const [consentCookie] = useConsentCookie();

    const [{ languagePreference }, setCookie] = useCookies<
        'languagePreference',
        {
            languagePreference?: Language;
        }
    >(['languagePreference']);

    const handleSetLanuagePreference = (value: Language) => {
        if (!consentCookie || consentCookie === 'none') {
            return;
        }

        setCookie('languagePreference', value);
    };

    const clearLanguagePreferenceCookie = () => {
        setCookie('languagePreference', undefined, { maxAge: 0 });
    };

    return [languagePreference, handleSetLanuagePreference, clearLanguagePreferenceCookie] as const;
}
