import Banner from 'ui/Banner/Banner';
import styles from './CarPageBanner.module.css';
import Shakira from 'assets/images/ShakiraNew.webp';
import ShakiraLarge from 'assets/images/ShakiraNewLarge.webp';
import LamboCar from 'assets/images/LamboCarNew.webp';
import LamboCarLarge from 'assets/images/LamboCarNewLarge.webp';

const CarPageBanner = () => {
    return (
        <Banner className={styles.banner}>
            <img
                alt="Shakira"
                className={styles.shakira}
                src={ShakiraLarge}
                srcSet={`${Shakira} 364w, ${ShakiraLarge} 728w`}
                sizes="(min-width: 700px) 728px, 364px"
            />

            <img
                alt="LamboCar"
                className={styles.lamboCar}
                src={LamboCarLarge}
                srcSet={`${LamboCar} 533w, ${LamboCarLarge} 1066w`}
                sizes="(min-width: 700px) 1066px, 533px"
            />
        </Banner>
    );
};

export default CarPageBanner;
