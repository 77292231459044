import { Outlet, Route, Routes, useLocation, useNavigate, Navigate } from 'react-router-dom';
import styles from './App.module.css';
import { RouterProvider } from 'react-aria-components';
import HomePage from 'pages/HomePage/HomePage';
import { PATH_URLS } from 'constants/links';
import OfficialRules from 'pages/OfficialRules/OfficialRules';
import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';
import HomePageBanner from 'pages/HomePage/Banner/HomePageBanner';
import { lazy, Suspense, useEffect } from 'react';
import CookieBanner from 'components/CookieBanner/CookieBanner';
import packageJson from '../package.json';
import { datadogRum } from '@datadog/browser-rum';
import { botAgentPattern } from 'botAgentPattern';
import { datadogLogs } from '@datadog/browser-logs';
import { useConsentCookie } from 'hooks/useConsentCookie';
import CarPageBanner from 'pages/CarPage/Banner/CarPageBanner';
import NotFoundPage from 'pages/NotFoundPage/NotFoundPage';
import { hasVotingPeriodEnded } from 'Modules/Posts/VotePosts/useCountdown';

const LazyVotingPage = lazy(() => import('pages/VotingPage/VotingPage'));
const LazySignUpPage = lazy(() => import('Modules/SignUp/SignUp'));
const LazyCarPage = lazy(() => import('pages/CarPage/CarPage'));

function App() {
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const [consentCookie] = useConsentCookie();

    const isAgentBot = botAgentPattern.test(navigator.userAgent);
    const basicRumSampleRate = isAgentBot ? 0 : 5;
    //is applied after basicRumSampleRate
    const sessionReplaySampleRate = isAgentBot ? 0 : 50;

    if (process.env.NODE_ENV === 'production') {
        datadogRum.init({
            applicationId: process.env.REACT_APP_DD_FANDOM_FRONTEND_APPLICATION_ID || '',
            clientToken: process.env.REACT_APP_DD_FANDOM_FRONTEND_CLIENT_TOKEN || '',
            site: process.env.REACT_APP_DD_FANDOM_FRONTEND_SITE || '',
            service: process.env.REACT_APP_DD_FANDOM_FRONTEND_SERVICE || '',
            env: process.env.REACT_APP_DD_FANDOM_FRONTEND_ENV || '',
            version: packageJson.version,
            sessionSampleRate: basicRumSampleRate,
            sessionReplaySampleRate: sessionReplaySampleRate,
            trackUserInteractions: true,
            trackResources: true,
            trackLongTasks: true,
            defaultPrivacyLevel: 'mask-user-input',
            trackingConsent: consentCookie === 'all' ? 'granted' : 'not-granted',
        });

        datadogLogs.init({
            clientToken: process.env.REACT_APP_DD_FANDOM_FRONTEND_CLIENT_TOKEN || '',
            site: process.env.REACT_APP_DD_FANDOM_FRONTEND_SITE || '',
            service: process.env.REACT_APP_DD_FANDOM_FRONTEND_SERVICE || '',
            env: process.env.REACT_APP_DD_FANDOM_FRONTEND_ENV || '',
            version: packageJson.version,
            forwardErrorsToLogs: true,
            sessionSampleRate: isAgentBot ? 0 : 100,
            trackingConsent: consentCookie === 'all' || consentCookie === 'essential' ? 'granted' : 'not-granted',
            beforeSend: (event) => {
                if (consentCookie !== 'all') {
                    //Remove any identifying data from log
                    event['network'] = undefined;
                }

                return true;
            },
        });
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <RouterProvider
            //This lets react-aria-components derived link components use router navigation and options
            navigate={navigate}
        >
            <div className={styles.app}>
                <Routes>
                    <Route
                        element={
                            <>
                                <Header />
                                <Outlet />
                                <Footer />
                            </>
                        }
                    >
                        <Route
                            index
                            element={
                                <>
                                    <HomePageBanner />
                                    <HomePage />
                                </>
                            }
                        />

                        <Route
                            path="voting/:uuid/"
                            element={
                                !hasVotingPeriodEnded() ? (
                                    <Suspense>
                                        <LazyVotingPage />
                                    </Suspense>
                                ) : (
                                    <Navigate to="/404" />
                                )
                            }
                        />

                        <Route
                            path="/sign-up"
                            element={
                                hasVotingPeriodEnded() ? (
                                    <Navigate to="/404" />
                                ) : (
                                    <Suspense>
                                        <LazySignUpPage />
                                    </Suspense>
                                )
                            }
                        />

                        <Route path={PATH_URLS.officialRules} element={<OfficialRules />} />

                        <Route
                            path={PATH_URLS.carPage}
                            element={
                                <>
                                    <Suspense>
                                        <CarPageBanner />
                                        <LazyCarPage />
                                    </Suspense>
                                </>
                            }
                        />

                        <Route path="*" element={<NotFoundPage />} />
                    </Route>
                </Routes>
                <CookieBanner />
            </div>
        </RouterProvider>
    );
}

export default App;
