import styles from './Header.module.css';
import NavBar from 'Modules/Navigation/NavBar/NavBar';

const Header = () => {
    return (
        <header className={styles.header}>
            <div className={styles.navSection}>
                <NavBar />
            </div>

            <div className={styles.sublinksContainer} />
        </header>
    );
};

export default Header;
