import { useState } from 'react';
import { MediaPlayerInstance, MediaPlayEvent } from '@vidstack/react';

export default function useSinglePlayback() {
    const [activePlayer, setActivePlayer] = useState<MediaPlayerInstance | null>(null);

    return (e: MediaPlayEvent) => {
        if (activePlayer && e.target.el !== activePlayer.el) {
            activePlayer
                .pause()
                .then(() => setActivePlayer(e.target))
                .catch(() => e.target.pause());

            return;
        }

        setActivePlayer(e.target);
    };
}
