import Content from 'ui/Layout/Content/Content';
import { useLanguage } from 'contexts/LanguageContext';
import styles from './NotFoundPage.module.css';

const NotFoundPage = () => {
    const [language] = useLanguage();

    // copy is from google translate until we get copy
    return (
        <Content className={styles.content}>
            <h1>{language === 'en' ? 'Page not found' : 'Página no encontrada'}</h1>
        </Content>
    );
};

export default NotFoundPage;
