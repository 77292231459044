import { useCallback } from 'react';
import { InfiniteScrollState, useDataState } from '@round/utils';
import { fandom } from '@round/api';
import { Post } from '../components/VideoPost/VideoPost';

export default function useFeedPosts() {
    const [state, setState] = useDataState<InfiniteScrollState<fandom.FeedPost>>({
        status: 'idle',
        data: null,
        error: null,
    });

    const fetchData = useCallback(
        async (params: fandom.GetFeedPostsParams, requestInit?: RequestInit) => {
            try {
                setState('loading');
                const response = await fandom.getFeedPosts(params, requestInit);
                if (response.status === 200) {
                    setState('success', (prev) => ({
                        data: {
                            count: response.data.count,
                            data: prev.data?.data.concat(response.data.results) ?? response.data.results,
                            hasNextPage: Boolean(response.data.next),
                            hasPreviousPage: Boolean(response.data.previous),
                            lastFetchedPage: params.page,
                        },
                    }));
                    return response;
                }

                setState('error', { error: response.data.detail });
                return response;
            } catch (e) {
                if (e instanceof Error && e.name === 'AbortError') {
                    throw e;
                }

                setState('error', { error: 'We have encountered an error while fetching feed' });
                throw e;
            }
        },
        [setState]
    );

    return {
        ...state,
        fetchData,
    };
}

type StaticPostConfig = [
    platform: fandom.Platform,
    postUrl: string,
    cloudflareVideoId: string,
    cloudflareImageId: string,
    viewCount: number | null,
    accountHandles?: string[]
];

const staticFeedPostsConfig: StaticPostConfig[] = [
    [
        'tiktok',
        'https://www.tiktok.com/@kevinoliva7/video/7442512907067362606',
        '93c259500b944ca8af0cf15189bca2a9',
        '2698b72e-e6ad-4772-b7a9-da1087cf9800',
        null,
        ['kevinoliva7'],
    ],
    [
        'instagram',
        'https://www.instagram.com/p/DC7slU2OcsB',
        '743aacac2eb34465ab47a81cab9e7cd1',
        '029dc595-3369-464d-e162-15bb5601f100',
        null,
        ['daniela.aros'],
    ],
    [
        'tiktok',
        'https://www.tiktok.com/@yarobis.feliz/video/7442561271955737886',
        'd1dbc3415b6c42ad9ecb3cadef40ab77',
        'd73f0ce6-b8ba-495b-9ffb-5fd385e72800',
        null,
        ['yarobis.feliz'],
    ],
    [
        'tiktok',
        'https://www.tiktok.com/@danieladany___/video/7442576317943434527',
        'f3cf5ea39c614253a5c2575e12a78f3d',
        '75f5126f-f085-4097-1814-604d90479800',
        null,
        ['danieladany___'],
    ],
    [
        'tiktok',
        'https://www.tiktok.com/@lilmiss_fit7/video/7442517164013243678',
        'd81d943ab94f4cdeb3c0630dc7bc06fe',
        '84292778-b4ae-400d-3096-a125d7046700',
        null,
        ['lilmiss_fit7'],
    ],
    [
        'tiktok',
        'https://www.tiktok.com/@llovesluz138/video/7442563758649593119',
        'f1fc03029ff346888870ff1ac9cedeeb',
        '8e9673e5-6db2-435d-a372-a4e9d50e3b00',
        null,
        ['llovesluz138'],
    ],
    [
        'tiktok',
        'https://www.tiktok.com/@hillarylaguera/video/7442568375315746079',
        'b21d2ed00ae84edf89ef8cada0057a51',
        '232b0e85-4105-4cc7-f1e6-aeba6e7a0400',
        null,
        ['hillarylaguera'],
    ],
    [
        'instagram',
        'https://www.instagram.com/p/DC7p6hQxkMl',
        'eb8e3d9b75784d6ea49bbc326df63d9d',
        '3f4f90cf-0034-4ebf-ef09-48f8d8eab900',
        null,
        ['melissavaronb'],
    ],
    [
        'tiktok',
        'https://www.tiktok.com/@miqulamagna/video/7442522987045096760',
        '0e65ba12cba64deaa85691c87216300d',
        'a6cc3639-85f9-427a-55a6-1bee6c29b800',
        null,
        ['miqulamagna'],
    ],
    [
        'tiktok',
        'https://www.tiktok.com/@nossaestrelabrilhou13/video/7442531618381745463',
        'e3361d52b57e43f680f9fc096668f219',
        '3f00d779-0186-4847-0ac7-cfc9454adb00',
        null,
        ['nossaestrelabrilhou13'],
    ],
    [
        'tiktok',
        'https://www.tiktok.com/@jasonbradly54/video/7442523321754881326',
        '4b4828581a8941f187a1dbe897f19753',
        '396ecc9f-550f-44d1-ba75-c21062bcb300',
        null,
        ['jasonbradly54'],
    ],
    [
        'tiktok',
        'https://www.tiktok.com/@yoankaortiztamayo/video/7442503973958962463',
        'fa93ea42be564e6bb85615ef994ea4ed',
        'b4dcc5f0-000e-454a-c6c1-b05d7b295f00',
        null,
        ['yoankaortiztamayo'],
    ],
    [
        'tiktok',
        'https://www.tiktok.com/@edith.23777/video/7442514110018751790',
        'f6440e8465604b08a631dff4896482a2',
        '0a0fa291-8b6c-4c65-0de7-0f2c19e83400',
        null,
        ['edith.23777'],
    ],
    [
        'tiktok',
        'https://www.tiktok.com/@eliagnagil18/video/7442486468301344055',
        '267d9426ab174b038b2dbb0a1125372e',
        'ec02ddae-d8c8-4a2d-c8ec-30a5d0366b00',
        null,
        ['eliagnagil18'],
    ],
];

export const getImageUrl = (
    cloudflareImageId: string,
    variant: 'feedPostCover' | 'feedPostCover2x' = 'feedPostCover'
) => {
    return `https://imagedelivery.net/T3cLJhe1E6TKE6AGjEfmww/${cloudflareImageId}/${variant}`;
};

export const staticFeedPosts: Post[] = staticFeedPostsConfig.map(
    ([platform, url, videoId, imageId, viewCount, accountHandles]) => ({
        post_url: url,
        platform: platform,
        thumbnail: getImageUrl(imageId),
        thumbnailLarge: getImageUrl(imageId, 'feedPostCover2x'),
        dynamic_thumbnail: `https://customer-75ag3g3ayxpvur4a.cloudflarestream.com/${videoId}/thumbnails/thumbnail.gif?duration=1s`,
        cloudflare_video_id: videoId,
        account_handle: accountHandles?.map((handle) => `@${handle}`).join(', ') || '',
        view_count: viewCount,
    })
);
