import { Language } from 'contexts/LanguageContext';

const FaqsCopyEng = [
    {
        question: 'How does it work?',
        answer: () => (
            <>
                It’s super simple! Just post your video on TikTok or Instagram Reels. Be sure to use #elcarrodeshakira.
                Shakira and her friends will check out some of her favorites.
            </>
        ),
    },
    {
        question: 'Who can participate?',
        answer: () => (
            <>
                Just make sure you're 18 or older, a legal U.S. resident. Now, get ready to show Shakira what you've
                got!
            </>
        ),
    },
    {
        question: 'What types of videos are allowed?',
        answer: () => (
            <>
                You can submit the official Soltera dance or add your own unique twist to it - just make sure it's fun
                and respectful. No nudity, hate speech, or offensive language allowed. Let’s keep it all about love,
                fun, and good vibes!
            </>
        ),
    },
    {
        question: 'Will my video be shared on the website even if Shakira does not select it?',
        answer: () => (
            <>
                Not all videos will be featured on the website, but there’s still a good chance it could be displayed
                for everyone to enjoy!
            </>
        ),
    },
    {
        question: 'What is the prize for the contest?',
        answer: () => (
            <>
                The prize is a 2022 Lamborghini Urus. Additionally, the winner will receive a check for $90,000 to help
                cover taxes and travel costs for picking up the vehicle.
            </>
        ),
    },
    {
        question: 'When and where does the winner need to pick up the vehicle?',
        answer: () => <>The winner must pick up the Lamborghini in Miami between December 10 and December 23, 2024.</>,
    },
    {
        question: 'What costs will the winner be responsible for?',
        answer: () => (
            <>
                The winner will be solely responsible for all costs and expenses associated with accepting and using the
                vehicle. This includes registration, licensing, title fees, delivery, vehicle insurance, and/or any
                other related expenses.
            </>
        ),
    },
];

const FaqsCopyEsp = [
    {
        question: '¿Cómo funciona?',
        answer: () => (
            <>
                ¡Es súper sencillo! Solo publica tu video en TikTok o Instagram Reels. Asegúrate de usar el hashtag
                #elcarrodeshakira. Shakira y sus amigos revisarán algunos de sus favoritos.
            </>
        ),
    },
    {
        question: '¿Quién puede participar?',
        answer: () => (
            <>
                Solo asegúrate de tener 18 años o más, ser residente legal de EE. UU.¡Ahora prepárate para mostrarle a
                Shakira lo que tienes!
            </>
        ),
    },
    {
        question: '¿Qué tipos de videos están permitidos?',
        answer: () => (
            <>
                Puedes realizar el baile oficial de Soltera o agregarle tu propio toque único. Solo asegúrate de que sea
                divertido y respetuoso. No se permite desnudez, discurso de odio, ni lenguaje ofensivo. ¡Hagamos que
                todo se trate de amor, diversión y buenas vibras!
            </>
        ),
    },
    {
        question: '¿Mi video se compartirá en la página web aunque no sea seleccionado por Shakira?',
        answer: () => (
            <>
                No todos los videos se publicarán en el sitio web, pero ¡aún hay una buena posibilidad de que el tuyo se
                muestre para que todos lo disfruten!
            </>
        ),
    },
    {
        question: '¿Cuál es el premio del concurso?',
        answer: () => (
            <>
                El premio es un Lamborghini Urus 2022. Además, el ganador recibirá un cheque por $90,000 para ayudar a
                cubrir los impuestos y los gastos de viaje para recoger el vehículo.
            </>
        ),
    },
    {
        question: '¿Cuándo y dónde debe recoger el vehículo?',
        answer: () => <>El ganador deberá recoger el Lamborghini en Miami entre el 10 y el 23 de diciembre de 2024.</>,
    },
    {
        question: '¿Qué costos serán responsabilidad del ganador?',
        answer: () => (
            <>
                El ganador será el único responsable de todos los costos y gastos asociados con la aceptación y uso del
                vehículo. Esto incluye el registro, las licencias, los costos del título, la entrega, el seguro del
                vehículo y/o cualquier otro gasto relacionado.
            </>
        ),
    },
];

export const getFaqsCopy = (locale: Language) => {
    return locale === 'es' ? FaqsCopyEsp : FaqsCopyEng;
};

export const getFaqsTitle = (locale: Language) => (locale === 'en' ? 'FAQs' : 'Preguntas Frecuentes');
