import { useCallback } from 'react';
import { useAbortableEffect, useDataState } from '@round/utils';
import { fandom } from '@round/api';

export default function useVoteLink(voteLink: string | undefined) {
    const [voteLinkState, setVoteLinkState] = useDataState<fandom.VoteLink>({
        status: 'idle',
        data: null,
        error: null,
    });

    const isInitialized = voteLinkState.status === 'success' || voteLinkState.status === 'error';

    const fetchData = useCallback(
        async (voteLinkUUID: string, requestInit?: RequestInit) => {
            setVoteLinkState('loading');

            try {
                const response = await fandom.getVoteLink(voteLinkUUID, requestInit);
                if (response.status !== 200) {
                    setVoteLinkState('error', { error: response.data.detail });
                    return;
                }

                setVoteLinkState('success', { data: response.data });
            } catch (e) {
                if (e instanceof Error && e.name === 'AbortError') {
                    throw e;
                }

                setVoteLinkState('error', { error: 'Could not load vote link' });
                throw e;
            }
        },
        [setVoteLinkState]
    );

    useAbortableEffect(
        (signal) => {
            if (isInitialized || !voteLink) {
                return;
            }

            fetchData(voteLink, { signal }).catch(() => {});
        },
        [fetchData, voteLink, isInitialized]
    );

    const castVote = async (feedPostId: number) => {
        if (voteLinkState.status !== 'success' || !voteLink) {
            return;
        }

        const response = await fandom.patchVoteLink(voteLink, { feed_post_id: feedPostId });
        if (response.status === 200) {
            setVoteLinkState('success', { data: response.data });
        }

        return response;
    };

    return [voteLinkState, castVote] as const;
}
