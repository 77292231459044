import { useEffect, useState } from 'react';

const VOTE_ENDS_AT = new Date('2024-12-05T22:00:00-05:00');
const getTimeLeft = () => {
    const now = new Date();
    return VOTE_ENDS_AT.getTime() - now.getTime();
};

export const formatTime = (time: number) => {
    const hours = Math.floor(time / (1000 * 60 * 60));
    const minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((time % (1000 * 60)) / 1000);

    const pad = (num: number) => num.toString().padStart(2, '0');

    return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
};

export default function useCountdown() {
    const [timeLeft, setTimeLeft] = useState(Math.max(getTimeLeft(), 0));

    useEffect(() => {
        const interval = setInterval(() => {
            const timeLeft = getTimeLeft();
            if (timeLeft <= 0) {
                clearInterval(interval);
                return;
            }

            setTimeLeft(Math.max(timeLeft, 0));
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    return timeLeft;
}

export const hasVotingPeriodEnded = () => {
    const now = new Date();
    return VOTE_ENDS_AT.getTime() < now.getTime();
};
