import Banner from 'ui/Banner/Banner';
import styles from './HomePageBanner.module.css';
import Shakira from 'assets/images/Shakira.webp';
import ShakiraLarge from 'assets/images/ShakiraLarge.webp';
import LamboCar from 'assets/images/LamboCar.webp';
import LamboCarLarge from 'assets/images/LamboCarLarge.webp';
import { useLanguage } from 'contexts/LanguageContext';
import { default as getVotingCopy } from 'Modules/Posts/VotePosts/VotePosts.copy';
import { shouldAnnounceWinner } from 'utility/utility';
import getHomePageBannerCopy from './HomePageBanner.copy';

const HomePageBanner = () => {
    const [language] = useLanguage();
    const votingCopy = getVotingCopy(language);
    const homePageBannerCopy = getHomePageBannerCopy(language);

    return (
        <Banner className={styles.banner}>
            <div className={styles.headlines}>
                {shouldAnnounceWinner() ? (
                    <>
                        <h1>{homePageBannerCopy.bannerTitle}</h1>
                        <p>{homePageBannerCopy.bannerSubTitle}</p>
                    </>
                ) : (
                    <>
                        <h1>{votingCopy.votingHasEnded}</h1>
                        <p>{votingCopy.bannerWinnerWillBeAnnounced}</p>
                    </>
                )}
            </div>

            <img
                alt="Shakira"
                className={styles.shakira}
                src={ShakiraLarge}
                srcSet={`${Shakira} 477w, ${ShakiraLarge} 1035w`}
                sizes="(min-width: 1240px) 478px, (min-width: 700px) 199px, (min-width: 548px) 169px, 138px"
            />

            <img
                alt="LamboCar"
                className={styles.lamboCar}
                src={LamboCarLarge}
                srcSet={`${LamboCar} 340w, ${LamboCarLarge} 453w`}
                sizes="(min-width: 1240px) 21.75rem, (min-width: 1024px) 19rem, (min-width: 700px) 16.5rem, (min-width: 548px) 13rem, 11.5rem"
            />
        </Banner>
    );
};

export default HomePageBanner;
