import { Language } from 'contexts/LanguageContext';
import { ReactNode } from 'react';

type Copy = {
    voteButtonLabel: string;
    thankYouForVoting: string;
    winnerWillBeAnnounced: ReactNode;
    votingWindow: ReactNode;
    votingHasEnded: ReactNode;
    bannerWinnerWillBeAnnounced: ReactNode;
};

export default function getCopy(lang: Language): Copy {
    return ({
        en: {
            voteButtonLabel: 'Vote now!',
            thankYouForVoting: 'Thank you for voting!',
            winnerWillBeAnnounced: (
                <>
                    The winner will be announced on <i>Despierta América</i> on December 6 around 10:00 AM EST.
                </>
            ),
            votingWindow: (
                <>
                    Voting will be open <strong>only on December 5</strong> from 10:50 AM to 10:00 PM EST
                </>
            ),
            votingHasEnded: 'Voting has now ended',
            bannerWinnerWillBeAnnounced: 'The winner will be announced on December 6th at 10:55 AM EST',
        },
        es: {
            voteButtonLabel: '¡Vota ahora!',
            thankYouForVoting: '¡Gracias por votar!',
            winnerWillBeAnnounced: (
                <>
                    El ganador será anunciado en <i>Despierta América</i> el 6 de diciembre alrededor 10:00AM EST.
                </>
            ),
            votingWindow: (
                <>
                    La votación estará abierta <strong>sólo el 5 de diciembre</strong>, de 10:50 AM a 10:00 PM EST
                </>
            ),
            votingHasEnded: 'La votación ha finalizado',
            bannerWinnerWillBeAnnounced: 'El ganador será anunciado el 6 de diciembre a las 10:55 AM EST',
        },
    } as Record<Language, Copy>)[lang];
}
