import { fandom } from '@round/api';
import { ReactComponent as TikTokIcon } from 'assets/brand/TikTok.svg';
import { ReactComponent as InstagramIcon } from 'assets/brand/Instagram.svg';
import { ReactComponent as YouTubeIcon } from 'assets/brand/YouTube.svg';
import { SVGProps } from 'react';

type Props = {
    platform: fandom.Platform;
} & SVGProps<SVGSVGElement>;

const icons: { [platform in fandom.Platform]: typeof TikTokIcon } = {
    instagram: InstagramIcon,
    tiktok: TikTokIcon,
    youtube: YouTubeIcon,
};

const PlatformIcon = ({ platform, ...props }: Props) => {
    const Icon = icons[platform];
    if (!Icon) {
        return null;
    }

    return <Icon {...props} />;
};

export default PlatformIcon;
