import { GenericDropdownOption } from 'App.types';
import { Language, languageOptions, useLanguage } from 'contexts/LanguageContext';
import Select, { DropdownIndicatorProps, StylesConfig } from 'react-select';
import { ReactComponent as DropdownIcon } from 'assets/icons/DropdownArrow.svg';
import omit from 'lodash/omit';

const stylesConfig: StylesConfig<any, any> = {
    control: (provided) => ({
        ...provided,
        border: 'none',
        background: 'transparent',
        color: '#FFF',
        ':focus-within': {
            boxShadow: 'none',
        },
    }),
    menu: (provided) => ({
        ...provided,
        background: 'rgba(17, 0, 43, 0.80)',
        minWidth: '6rem',
    }),
    option: (provided, { isFocused }) => ({
        ...provided,
        background: isFocused ? 'rgba(255, 255, 255, 0.80)' : 'rgba(17, 0, 43, 0.80)',
        color: isFocused ? '#000' : '#FFF',
    }),
    singleValue: (provided) => ({
        ...provided,
        color: '#FFF',
        marginBottom: '0.125rem',
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        padding: 0,
    }),
    valueContainer: (provided) => ({
        ...provided,
        paddingLeft: 0,
        paddingRight: 0,
    }),
};

const DropdownIndicator = <T extends GenericDropdownOption<any>, TIsMulti extends boolean = false>(
    props: DropdownIndicatorProps<T, TIsMulti>
) => (
    <div {...props.innerProps} style={omit(props.getStyles('dropdownIndicator', props), ['label'])}>
        <DropdownIcon height={16} width={16} />
    </div>
);

const LanguageSelect = () => {
    const [language, setLanguage] = useLanguage();

    return (
        <Select<GenericDropdownOption<Language>, false>
            value={languageOptions.find((l) => language === l.value)}
            options={languageOptions}
            onChange={(option) => {
                if (!option) {
                    return;
                }
                setLanguage(option.value);
            }}
            isClearable={false}
            isSearchable={false}
            styles={stylesConfig}
            components={{
                IndicatorSeparator: null,
                DropdownIndicator,
            }}
        />
    );
};

export default LanguageSelect;
