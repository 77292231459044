import { Button as LibButton, ButtonProps as LibButtonProps } from 'react-aria-components';
import cn from 'classnames';
import styles from './Button.module.css';
import Spinner from 'ui/Spinner/Spinner';

type ButtonProps = LibButtonProps & {
    appearance: 'primary';
};

const Button = ({ children, appearance, className, ...props }: ButtonProps) => {
    return (
        <LibButton {...props} className={cn(styles.button, styles[appearance], className)}>
            <>
                {children}
                {props.isPending && <Spinner />}
            </>
        </LibButton>
    );
};

export default Button;
