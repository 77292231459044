import { fandom } from '@round/api';
import styles from './VideoPost.module.css';
import PlatformIcon from 'components/PlatformIcon/PlatformIcon';
import { CSSProperties, useRef } from 'react';
import {
    MediaPlayer,
    MediaPlayerInstance,
    MediaProvider,
    Poster,
    Gesture,
    PlayButton,
    FullscreenButton,
    Controls,
    MuteButton,
    TimeSlider,
    MediaPlayEvent,
    Tooltip,
} from '@vidstack/react';
import {
    PauseIcon,
    PlayIcon,
    FullscreenIcon,
    FullscreenExitIcon,
    MuteIcon,
    VolumeLowIcon,
    EyeIcon,
    LinkIcon,
} from '@vidstack/react/icons';
import cn from 'classnames';
import { formatNumberToKNotation } from '@round/utils';
import '@vidstack/react/player/styles/default/sliders.css';
import '@vidstack/react/player/styles/default/tooltips.css';
import { ReactComponent as SpinnerIcon } from 'assets/icons/Spinner.svg';
import VideoPostCover from './VideoPostCover/VideoPostCover';
import { MouseEvent } from 'react';
import useIsScrolling from 'hooks/useIsScrolling';

export type Post = fandom.FeedPost & {
    thumbnailLarge?: string;
};

type Props = {
    post: Post;
    isGestureDisabled?: boolean;
    shouldPersistPlayButton?: boolean;
    className?: string;
    onPlay?: (event: MediaPlayEvent) => void;
    onClick?: (event: MouseEvent<HTMLElement>) => void;
    isWinner?: boolean;
    style?: CSSProperties;
};

const buildVideoUrl = (id: string) =>
    `https://customer-75ag3g3ayxpvur4a.cloudflarestream.com/${id}/manifest/video.m3u8`;

const VideoPost = ({
    post,
    onPlay,
    className,
    isGestureDisabled,
    onClick,
    shouldPersistPlayButton,
    isWinner,
    style,
}: Props) => {
    const playerRef = useRef<MediaPlayerInstance>(null);
    const isScrolling = useIsScrolling();

    return (
        <MediaPlayer
            ref={playerRef}
            playsInline
            fullscreenOrientation="portrait"
            className={cn(styles.player, { [styles.winner]: isWinner }, className)}
            src={post.cloudflare_video_id ? buildVideoUrl(post.cloudflare_video_id) : ''}
            preload="metadata"
            load="play"
            onPlay={onPlay}
            onClick={onClick}
            style={{ ...style }}
        >
            <MediaProvider className={styles.mediaProvider}>
                <Poster asChild>
                    <VideoPostCover
                        thumbnail={post.thumbnail}
                        thumbnailLarge={post.thumbnailLarge}
                        dynamicThumbnail={post.dynamic_thumbnail}
                        className={styles.poster}
                    />
                </Poster>

                <a
                    className={styles.platformLink}
                    href={post.post_url}
                    rel="noopener noreferrer"
                    target="_blank"
                    aria-label="go to post"
                    onPointerUpCapture={(e) => {
                        // prevent play/pause gesture
                        e.nativeEvent.stopImmediatePropagation();
                    }}
                >
                    <PlatformIcon platform={post.platform} className={styles.platformIcon} />
                    <span>open post</span>
                </a>

                <Gesture
                    className={styles.playToggleGesture}
                    event="pointerup"
                    action="toggle:paused"
                    disabled={isGestureDisabled || isScrolling}
                />
            </MediaProvider>

            <PlayButton
                className={cn(styles.button, styles.playToggleButton, { [styles.persist]: shouldPersistPlayButton })}
                onClick={(e) => {
                    if (shouldPersistPlayButton) {
                        e.stopPropagation();
                    }
                }}
            >
                <PauseIcon className={cn(styles.icon, styles.pauseIcon)} />
                <PlayIcon className={cn(styles.icon, styles.playIcon)} />
                <SpinnerIcon className={cn(styles.icon, styles.playbackLoadingIcon)} />
            </PlayButton>

            <Controls.Root className={styles.controls}>
                <div className={styles.userInfo}>
                    {post.account_handle && (
                        <span className={styles.accountHandle}>
                            {post.account_handle.startsWith('@') ? post.account_handle : `@${post.account_handle}`}
                        </span>
                    )}
                    {post.view_count && (
                        <span className={styles.viewCount}>
                            <EyeIcon className={styles.viewCountIcon} /> {formatNumberToKNotation(post.view_count)}
                        </span>
                    )}
                </div>

                <Controls.Group className={styles.bottomRightControls}>
                    <FullscreenButton className={cn(styles.button, styles.fullscreenButton)}>
                        <FullscreenIcon className={cn(styles.icon, styles.fullscreenIcon)} />
                        <FullscreenExitIcon className={cn(styles.icon, styles.fullscreenExitIcon)} />
                    </FullscreenButton>

                    <MuteButton className={cn(styles.button, styles.muteButton)}>
                        <MuteIcon className={cn(styles.icon, styles.muteIcon)} />
                        <VolumeLowIcon className={cn(styles.icon, styles.unmuteIcon)} />
                    </MuteButton>

                    <Tooltip.Root>
                        <Tooltip.Trigger asChild>
                            <a
                                className={styles.button}
                                href={post.post_url}
                                rel="noopener noreferrer"
                                target="_blank"
                                aria-label="go to post"
                            >
                                <LinkIcon className={styles.icon} />
                            </a>
                        </Tooltip.Trigger>

                        <Tooltip.Content className={cn('vds-tooltip-content', styles.tooltip)} placement="left center">
                            open post
                        </Tooltip.Content>
                    </Tooltip.Root>
                </Controls.Group>

                <TimeSlider.Root className={cn('vds-time-slider', 'vds-slider', styles.track)}>
                    <TimeSlider.Track className="vds-slider-track" />
                    <TimeSlider.TrackFill className="vds-slider-track-fill vds-slider-track" />
                    <TimeSlider.Progress className="vds-slider-progress vds-slider-track" />
                    <TimeSlider.Thumb className="vds-slider-thumb" />
                </TimeSlider.Root>
            </Controls.Root>

            <div className={styles.error}>
                Could not play the video. You can visit the post by clicking on a platform icon
            </div>

            <div className={styles.bottomShade} />
        </MediaPlayer>
    );
};

export default VideoPost;
