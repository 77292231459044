import { DetailedHTMLProps, forwardRef, ImgHTMLAttributes, useState } from 'react';
import cn from 'classnames';
import styles from './Image.module.css';

type Props = DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>;

const Image = forwardRef<HTMLImageElement, Props>(({ className, ...props }, ref) => {
    const [status, setStatus] = useState<'loading' | 'success' | 'error'>('loading');

    return (
        <div data-status={status} className={cn(styles.container, className)}>
            <img
                ref={ref}
                {...props}
                className={styles.image}
                alt={props.alt}
                onLoad={(e) => {
                    props.onLoad?.(e);
                    setStatus('success');
                }}
                onError={(e) => {
                    props.onError?.(e);
                    setStatus('error');
                }}
                data-status={status}
            />
        </div>
    );
});

export default Image;
