import { HTMLProps } from 'react';
import cn from 'classnames';
import styles from './Banner.module.css';

type Props = HTMLProps<HTMLDivElement> & {
    hasLinesBackground?: boolean;
};

const Banner = ({ className, children, hasLinesBackground = true, ...props }: Props) => {
    return (
        <div className={cn(styles.banner, className)} {...props}>
            {hasLinesBackground && <div className={styles.linesBackground} />}
            {children}
        </div>
    );
};

export default Banner;
