import styles from './PostsGrid.module.css';
import { ReactNode } from 'react';
import cn from 'classnames';

type Props = {
    children?: ReactNode;
    className?: string;
};

const PostsGrid = ({ children, className }: Props) => {
    return <div className={cn(styles.grid, className)}>{children}</div>;
};

export default PostsGrid;
