import { PATH_URLS } from 'constants/links';
import { Language } from 'contexts/LanguageContext';
import TextLink from 'ui/Links/TextLink/TextLink';

const titleMap: Record<Language, string> = {
    en: 'How it works',
    es: '¿Cómo Funciona?',
};

const stagesMap: Record<Language, string[]> = {
    en: [
        'Fill out the sign-up form to enter the contest.',
        'Create your best video using the Soltera song.',
        'Share your videos on Instagram Reels or TikTok using Shakira’s song “Soltera” and include #ElCarroDeShakira in the caption.',
    ],
    es: [
        'Completa el formulario de inscripción para participar en el concurso.',
        'Crea tu mejor video usando la canción de Soltera.',
        'Comparte tus videos en Instagram Reels o TikTok usando la canción “Soltera” de Shakira e incluye #ElCarroDeShakira en la descripción.',
    ],
};

const subTitleMap = {
    en: 'It’s time to show off your creativity and embrace the Soltera spirit! Create a video using the Soltera song.',
    es:
        '¡Es hora de mostrar tu creatividad y abrazar el espíritu de Soltera! Crea un video utilizando la canción Soltera.',
};

const bodyCopyMap = {
    en: [
        'Shakira and her friends will watch the videos and pick five finalists. From there, the winner will be selected via a public vote and announced live on Despierta América on December 6.',
        <>
            *You must be 18 years or older to participate and a legal resident of the United States. See the{' '}
            <TextLink href={PATH_URLS.officialRules}>Official Rules</TextLink> for more details.
        </>,
        <>
            *Judging criteria: Creativity and <strong>soltera spirit</strong>.
        </>,
    ],
    es: [
        'Shakira y sus amigos verán los videos y elegirán a cinco finalistas. A partir de ahí, el ganador será seleccionado mediante una votación pública y se anunciará en vivo en Despierta América el 6 de diciembre.',
        <>
            *Debes tener 18 años o más para participar y ser residente legal de los Estados Unidos. Consulta las{' '}
            <TextLink href={PATH_URLS.officialRules}>Reglas Oficiales</TextLink> para más detalles.
        </>,
        <>
            *Criterios de evaluación: Creatividad y espíritu <strong>Soltera</strong>.
        </>,
    ],
};

export const getCopy = (lang: Language) => ({
    title: titleMap[lang],
    stages: stagesMap[lang],
    body: bodyCopyMap[lang],
    subTitle: subTitleMap[lang],
});
