import styles from './Footer.module.css';
import { ReactComponent as InstagramIcon } from 'assets/brand/Instagram.svg';
import { ReactComponent as TikTokIcon } from 'assets/brand/TikTok.svg';
import { ReactComponent as XIcon } from 'assets/brand/X.svg';
import { ReactComponent as YouTubeIcon } from 'assets/brand/YouTube.svg';
import { ReactComponent as AppleMusicIcon } from 'assets/brand/Apple.svg';
import { ReactComponent as SpotifyIcon } from 'assets/brand/Spotify.svg';
import { ReactComponent as WolfIcon } from 'assets/icons/Wolf.svg';
import WolfLogo from 'assets/images/WolfBadge.png';
import WolfLogoLarge from 'assets/images/WolfBadgeLarge.png';
import { ReactComponent as CheckCircle } from 'assets/icons/CheckCircle.svg';
import Link from 'ui/Links/Link/Link';
import buttonStyles from 'ui/Button/Button.module.css';
import cn from 'classnames';
import Content from 'ui/Layout/Content/Content';
import { useLocation } from 'react-router-dom';
import { PATH_URLS } from 'constants/links';
import { useLanguage } from 'contexts/LanguageContext';
import { useConsentCookie } from 'hooks/useConsentCookie';
import { useState } from 'react';
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { googleTagManagerRevokeConsent } from 'utility/gtm';
import { ReactComponent as GlobeIcon } from 'assets/icons/Globe.svg';
import { useLanguagePreferenceCookie } from 'hooks/useLanguageCookie';
import { hasVotingPeriodEnded } from 'Modules/Posts/VotePosts/useCountdown';

const Footer = () => {
    const year = new Date().getFullYear();
    const location = useLocation();
    const [language] = useLanguage();

    const [cookieConsent, setCookieConsent] = useConsentCookie();
    const [, , clearLanguagePreferenceCookie] = useLanguagePreferenceCookie();
    const [hasConsentBeenRevoked, setHasConsentBeenRevoked] = useState(false);
    const showRevokeConsentButton = hasConsentBeenRevoked || cookieConsent === 'all' || cookieConsent === 'essential';

    const handleRevokeConsent = () => {
        setHasConsentBeenRevoked(true);
        clearLanguagePreferenceCookie();
        setCookieConsent('none');
        datadogLogs.setTrackingConsent('not-granted');
        datadogRum.setTrackingConsent('not-granted');
        googleTagManagerRevokeConsent();
    };

    return (
        <footer className={styles.footer}>
            <Content className={styles.container}>
                <div className={styles.mainContainer}>
                    <div className={styles.brandContainer}>
                        <img
                            alt="Shakira wolf logo"
                            className={styles.shakiraLogo}
                            src={WolfLogoLarge}
                            srcSet={`${WolfLogo} 95w, ${WolfLogoLarge} 380w`}
                            sizes="(min-width: 768px) 380px, 95px"
                        />

                        <div className={styles.socialsContainer}>
                            <Link href="https://www.instagram.com/shakira/" target="_blank" rel="noopener noreferrer">
                                <InstagramIcon />
                            </Link>
                            <Link href="https://www.tiktok.com/@shakira" target="_blank" rel="noopener noreferrer">
                                <TikTokIcon />
                            </Link>
                            <Link href="https://x.com/shakira" target="_blank" rel="noopener noreferrer">
                                <XIcon />
                            </Link>
                            <Link href="https://www.youtube.com/@Shakira" target="_blank" rel="noopener noreferrer">
                                <YouTubeIcon />
                            </Link>
                            <Link
                                href="https://music.apple.com/artist/shakira/889327"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <AppleMusicIcon />
                            </Link>
                            <Link
                                href="https://open.spotify.com/artist/0EmeFodog0BfCgMzAIvKQp"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <SpotifyIcon />
                            </Link>
                        </div>
                    </div>

                    <div className={styles.sublinksContainer}>
                        {location.pathname !== PATH_URLS.carPage && (
                            <Link href={PATH_URLS.carPage} className={styles.subLink}>
                                {language === 'en' ? 'About the Car' : 'Sobre el Carro'}
                            </Link>
                        )}

                        <Link
                            href="https://www.shakira.com/tour/"
                            target="_blank"
                            rel="noopener noreferrer"
                            className={styles.subLink}
                        >
                            <GlobeIcon />
                            Tour
                        </Link>

                        {location.pathname !== '/sign-up' &&
                            !location.pathname.includes('voting') &&
                            !hasVotingPeriodEnded() && (
                                <Link
                                    href="/sign-up"
                                    className={cn(buttonStyles.button, buttonStyles.primary, styles.enterNow)}
                                >
                                    <WolfIcon />
                                    {language === 'en' ? 'Register to vote' : 'Regístrate para votar'}
                                </Link>
                            )}
                    </div>
                </div>

                <div className={styles.documentationContainer}>
                    <Link href={PATH_URLS.officialRules} className={styles.link}>
                        {language === 'en' ? 'Official Rules' : 'Reglas Oficiales'}
                    </Link>
                    <Link
                        href={PATH_URLS.termsAndConditions}
                        className={styles.link}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {language === 'en' ? 'Terms and Conditions' : 'Términos y Condiciones'}
                    </Link>
                    <Link
                        href={PATH_URLS.privacyPolicy}
                        className={styles.link}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {language === 'en' ? 'Privacy Policy' : 'Política de Privacidad'}
                    </Link>
                    {!!showRevokeConsentButton && (
                        <button className={styles.revokeButton} onClick={handleRevokeConsent}>
                            {hasConsentBeenRevoked ? (
                                <span className={styles.revokedMessage}>
                                    Consent revoked
                                    <CheckCircle />
                                </span>
                            ) : (
                                'Revoke cookie consent'
                            )}
                        </button>
                    )}
                </div>

                <span className={styles.copyright}>© {year} Shakira</span>
            </Content>
        </footer>
    );
};

export default Footer;
