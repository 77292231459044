import { Language } from 'contexts/LanguageContext';
import { ReactNode } from 'react';

type Copy = {
    bannerTitle: ReactNode;
    bannerSubTitle: ReactNode;
};

export default function getCopy(lang: Language): Copy {
    return ({
        en: {
            bannerTitle: 'You have chosen the winner!',
            bannerSubTitle: 'Thank you to everyone for participating',
        },
        es: {
            bannerTitle: '¡Has elegido al ganador!',
            bannerSubTitle: 'Gracias a todos por participar',
        },
    } as Record<Language, Copy>)[lang];
}
