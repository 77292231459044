import Faqs from 'components/Faqs/Faqs';
import styles from './HomePage.module.css';
import HowItWorks from 'components/HowItWorks/HowItWorks';
import Content from 'ui/Layout/Content/Content';
import FeedPostsHomePageSection from 'Modules/Posts/FeedPostsHomePageSection/FeedPostsHomePageSection';
import { useLanguage } from 'contexts/LanguageContext';
import { getCopy as getHomePageCopy } from 'components/HowItWorks/HowItWorks.copy';
import { getFaqsTitle } from 'components/Faqs/Faqs.copy';
import { shouldAnnounceWinner } from '../../utility/utility';
import WinnerAnnouncementFeedPostsHomePageSection from 'Modules/Posts/FeedPostsHomePageSection/WinnerAnnouncementFeedPostsHomePageSection';

const HomePage = () => {
    const [language] = useLanguage();

    return (
        <div className={styles.container}>
            {shouldAnnounceWinner() ? (
                <WinnerAnnouncementFeedPostsHomePageSection />
            ) : (
                <FeedPostsHomePageSection className={styles.section} />
            )}

            <section className={styles.section} aria-label="how it works">
                <Content className={styles.content}>
                    <h2>{getHomePageCopy(language).title}</h2>
                    <HowItWorks />
                </Content>
            </section>

            <section className={styles.section} aria-label="frequently asked questions" id="FAQ">
                <Content className={styles.content}>
                    <h2>{getFaqsTitle(language)}</h2>
                    <Faqs />
                </Content>
            </section>
        </div>
    );
};

export default HomePage;
