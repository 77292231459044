import { HTMLProps } from 'react';
import cn from 'classnames';
import styles from './ErrorCard.module.css';
import Card from 'ui/Card/Card';
import { ReactComponent as AlertIcon } from 'assets/icons/AlertCircle.svg';

type Props = HTMLProps<HTMLDivElement>;

const ErrorCard = ({ className, children, ...props }: Props) => {
    return (
        <Card {...props} className={cn(styles.error, className)}>
            <span>{children}</span>

            <AlertIcon />
        </Card>
    );
};

export default ErrorCard;
