import styles from './NavBar.module.css';
import { ReactComponent as RoundLogo } from 'assets/brand/RoundLogo.svg';
import Link from 'ui/Links/Link/Link';
import LanguageSelect from 'components/Header/LanguageSelect/LanguageSelect';
import WolfLogo from 'assets/images/WolfBadge.png';
import WolfLogoLarge from 'assets/images/WolfBadgeLarge.png';

const NavBar = () => {
    return (
        <div className={styles.container}>
            <div className={styles.logoContainer}>
                <Link href="/" aria-label="go to home" className={styles.brandLogoLink}>
                    <img
                        alt="Shakira wolf logo"
                        src={WolfLogoLarge}
                        srcSet={`${WolfLogo} 95w, ${WolfLogoLarge} 380w`}
                        sizes="(min-width: 768px) 380px, 95px"
                    />
                </Link>
            </div>

            <div className={styles.containerRight}>
                <LanguageSelect />

                <Link
                    href="https://thisisround.com/"
                    rel="noopener noreferrer"
                    target="_blank"
                    className={styles.roundBrandContainer}
                >
                    <span className={styles.roundCopy}>Powered by</span>
                    <RoundLogo title="round brand logo" />
                </Link>
            </div>
        </div>
    );
};

export default NavBar;
