import { useEffect, useRef, useState } from 'react';

export default function useIsScrolling() {
    const timeoutRef = useRef<number | null>(null);
    const [isScrolling, setIsScrolling] = useState(false);
    const [isTouchScroll, setIsTouchScroll] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }

            setIsScrolling(true);
            if (!isTouchScroll) {
                timeoutRef.current = window.setTimeout(() => {
                    setIsScrolling(false);
                }, 75);
            }
        };

        const handleTouchStart = () => {
            setIsTouchScroll(true);
        };

        const handleTouchEnd = () => {
            if (isTouchScroll) {
                setIsScrolling(false);
            }
        };

        window.addEventListener('touchstart', handleTouchStart);
        window.addEventListener('scroll', handleScroll);
        window.addEventListener('touchend', handleTouchEnd);

        return () => {
            window.removeEventListener('touchstart', handleTouchStart);
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('touchend', handleTouchEnd);

            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, [isTouchScroll]);

    return isScrolling;
}
