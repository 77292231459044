import styles from './CookieBanner.module.css';
import { Button } from 'react-aria-components';
import cn from 'classnames';
import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';
import { fireConsentGrantedEvent, googleTagManagerGrantConsent } from 'utility/gtm';
import { useConsentCookie } from 'hooks/useConsentCookie';

const CookieBanner = () => {
    const [consentCookie, setConsentCookie] = useConsentCookie();

    if (consentCookie !== undefined) {
        return null;
    }

    const onAcceptAll = () => {
        setConsentCookie('all');
        datadogRum.setTrackingConsent('granted');
        datadogLogs.setTrackingConsent('granted');
        googleTagManagerGrantConsent();
        fireConsentGrantedEvent();
    };

    const onAcceptEssential = () => {
        setConsentCookie('essential');
        datadogLogs.setTrackingConsent('granted');
    };

    return (
        <section
            className={styles.container}
            aria-label="Cookie banner, informing the user about the use of cookies and asking for their consent."
        >
            <div className={styles.content}>
                <p>
                    We use cookies to enhance your browsing experience and analyze site traffic. By clicking "Accept
                    all", you consent to our use of the cookies.
                </p>
                <div className={styles.actions}>
                    <div className={styles.actionsGroup}>
                        <Button className={cn(styles.button, styles.outline)} onPress={() => setConsentCookie('none')}>
                            Reject all
                        </Button>
                    </div>

                    <div className={styles.actionsGroup}>
                        <Button className={styles.button} onPress={onAcceptEssential}>
                            Accept essential
                        </Button>
                        <Button className={styles.button} onPress={onAcceptAll}>
                            Accept all
                        </Button>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default CookieBanner;
