import { createContext, ReactNode, useContext, useState } from 'react';
import { ToggleButton } from 'react-aria-components';
import cn from 'classnames';
import styles from './Disclosure.module.css';
import { useId } from 'react';
import { ReactComponent as PlusIcon } from 'assets/icons/Plus.svg';
import { ReactComponent as CrossIcon } from 'assets/icons/Cross.svg';

type ContextValue = [boolean, (isOpen: boolean) => void, string];

type Props = {
    children: ReactNode | ((...props: ContextValue) => React.ReactNode);
};

const DisclosureContext = createContext<ContextValue>([false, () => {}, '']);

const Disclosure = ({ children }: Props) => {
    const [isOpen, setIsOpen] = useState(false);
    const id = useId();

    return (
        <DisclosureContext.Provider value={[isOpen, setIsOpen, id]}>
            {typeof children === 'function' ? children(isOpen, setIsOpen, id) : children}
        </DisclosureContext.Provider>
    );
};

type TriggerProps = {
    children: ReactNode;
};

const Trigger = ({ children }: TriggerProps) => {
    const [isOpen, setIsOpen, id] = useContext(DisclosureContext);

    return (
        <ToggleButton
            isSelected={isOpen}
            onChange={setIsOpen}
            aria-controls={id}
            aria-expanded={isOpen}
            className={styles.trigger}
        >
            {children}
            {isOpen ? <CrossIcon /> : <PlusIcon />}
        </ToggleButton>
    );
};

type ContentProps = {
    children: ReactNode;
};

const Content = ({ children }: ContentProps) => {
    const [isOpen, , id] = useContext(DisclosureContext);

    return (
        <div id={id} className={cn(styles.content, { [styles.isOpen]: isOpen })} aria-live="polite">
            {children}
        </div>
    );
};

Disclosure.Trigger = Trigger;
Disclosure.Content = Content;

export default Disclosure;
