export function googleTagManagerGrantConsent() {
    window.gtag('consent', 'update', {
        ad_storage: 'granted',
        analytics_storage: 'granted',
        ad_personalization: 'granted',
        ad_user_data: 'granted',
    });
}

export function googleTagManagerRevokeConsent() {
    window.gtag('consent', 'update', {
        ad_storage: 'denied',
        analytics_storage: 'denied',
        ad_personalization: 'denied',
        ad_user_data: 'denied',
    });
}

export function fireConsentGrantedEvent() {
    window.gtag('event', 'consent_granted');
}
