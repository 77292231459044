import Content from 'ui/Layout/Content/Content';
import TextLink from 'ui/Links/TextLink/TextLink';

const OfficialRules = () => {
    return (
        <Content>
            <h1>
                <b>Official Rules for the #ElCarroDeShakira Contest</b>
            </h1>
            <p>
                <b>No Purchase Necessary. A Purchase Will Not Increase Your Chances of Winning.</b>
            </p>
            <ol>
                <li>
                    <p>
                        <b>Eligibility:</b> The Contest is open only to legal residents of the United States who are at
                        least eighteen (18) years old and the age of majority in the state where they live. Employees of
                        Loba Marketing (“<b>Sponsor</b>”), as well as the immediate family (spouse, parents, siblings,
                        and children) and household members of those employees, are not eligible to enter.
                    </p>
                </li>
                <li>
                    <p>
                        <b>Entry Period:</b> The entry period will begin on November 20, 2024 and end at 11:59 PM ET on
                        December 1, 2024 (the “<b>Entry Period</b>”). Entries submitted before or after the Entry Period
                        will not be eligible. Sponsor’s computer is the official time-keeping device for the Contest.
                    </p>
                </li>
                <li>
                    <p>
                        <b>How to Enter:</b>
                    </p>
                </li>
                <ol type="a">
                    <li>
                        <p>
                            First, visit{' '}
                            <u>
                                <TextLink href="https://www.elcarrodeshakira.com/" target="_blank">
                                    elcarrodeshakira.com
                                </TextLink>
                            </u>{' '}
                            (the “<b>Site</b>”) and complete the entry form. Next, follow the instructions on the Site
                            to create a video using the <i>Soltera</i> song (your “<b>Entry</b>”) and upload your Entry
                            to TikTok or Instagram with #elcarrodeshakira in the caption during the Entry Period. Please
                            make sure your Entry does not: (a) violate anyone’s rights, including, but not limited to,
                            copyrights or trademark rights; (b) prominently feature any third-party trademarks or logos;
                            (c) portray Sponsor or Shakira in a negative light; or (d) contain any content that is
                            unlawful, inappropriate, inciteful, discriminatory, or indecent.
                        </p>
                    </li>
                    <li>
                        <p>
                            By entering, you represent and warrant that: (a) you own all rights to the Entry; (b) your
                            Entry does not violate any third party’s rights; and (c) you agree to these Official Rules,
                            the Privacy Policy on the Site, Round’s{' '}
                            <u>
                                <TextLink
                                    href="https://thisisround.com/round-terms-for-competition-entrants/"
                                    target="_blank"
                                >
                                    Terms
                                </TextLink>
                            </u>{' '}
                            and{' '}
                            <u>
                                <TextLink href="https://thisisround.com/privacy-policy/" target="_blank">
                                    Privacy Policy
                                </TextLink>
                            </u>{' '}
                            , and Sponsor’s decisions regarding the Contest, which are final and binding. Winning a
                            prize is contingent upon fulfilling all requirements in these Official Rules.
                        </p>
                    </li>
                </ol>
                <li>
                    <p>
                        <b>Sponsor’s Rights to Entries:</b> You do not transfer ownership of your Entry by entering the
                        Contest. However, by entering, you grant Sponsor, its agents, licensees, and assigns an
                        irrevocable, perpetual (non-exclusive) right and permission to reproduce, encode, store, copy,
                        transmit, publish, post, broadcast, display, publicly perform, adapt, modify, create derivative
                        works of, exhibit, and otherwise use your Entry as-is or as-edited (with or without using your
                        name) in any media throughout the world for any purpose, without limitation, and without
                        additional review, compensation, or approval from you.
                    </p>
                </li>
                <li>
                    <p>
                        <b>Selection Process:</b>{' '}
                    </p>
                </li>
                <ol type="a">
                    <li>
                        <p>
                            <b>Finalists:</b> Throughout the Entry Period, Shakira and a group of her friends will
                            evaluate Entries and select five (5) finalists based on the following Judging Criteria: (a)
                            creativity; and (b) which Entry best captures the spirit of <i>Soltera</i>. The odds of
                            being selected depend on the performance of each entrant.
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>Winner:</b> The five (5) finalists will be posted on the Site. Between approximately
                            10:00 AM ET on December 5, 2024 and 10:00 PM ET on December 5, 2024, members of the public
                            will be invited to vote for their favorite finalist based on the Judging Criteria.{' '}
                            <b>There is a limit of one (1) vote per person.</b>
                            The finalist with the highest number of votes will be deemed the potential winner. The
                            winner will be announced on <i>Despierta America</i> on December 6, 2024, and Sponsor will
                            attempt to notify the potential winner by email or phone the same day. If a potential winner
                            does not respond within 24 hours, Sponsor may select an alternate winner based on the votes.
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>Voting Process:</b> You may not attempt to obtain votes by any fraudulent or
                            inappropriate means, including, without limitation, by: (i) circumventing (or encouraging
                            others to circumvent) the vote limits; (ii) using any automated system to vote; or (iii)
                            offering any inducements to others in exchange for votes; or (iv) using proxy voting schemes
                            in which anyone other than the actual person submits the vote. Sponsor, in its sole
                            discretion, reserves the right to reject any votes that Sponsor has reason to believe were
                            obtained through fraudulent or inappropriate means.
                        </p>
                    </li>
                </ol>
                <li>
                    <p>
                        <b>Potential Winner Requirements:</b> Except where prohibited, the potential winner may be
                        required to complete and return a declaration of eligibility and liability/publicity release. If
                        the potential winner fails to sign and return these documents within 24 hours of the time
                        Sponsor sends them, Sponsor may select an alternate potential winner in his/her place based on
                        the vote totals. Only three (3) alternate potential winners may be contacted.
                    </p>
                </li>
                <li>
                    <p>
                        <b>Prize:</b>{' '}
                    </p>
                </li>
                <ol type="a">
                    <li>
                        <p>
                            One (1) winner will receive a 2022 Lamborghini Urus. The approximate retail value (“
                            <b>ARV</b>”) of the vehicle is $195,000. In addition, the winner will receive a check for
                            $90,000 to help cover taxes and the costs of getting to Miami to pick up the vehicle. The
                            vehicle is used and will be awarded as-is. The winner is solely responsible for all costs
                            and expenses associated with acceptance and use of the vehicle, including, but not limited
                            to, registration, license and title fees, delivery, and vehicle insurance.{' '}
                            <b>
                                The winner will be required to pick the car up in Miami between December 10 and December
                                23, 2024 and will be responsible for transporting the vehicle to his/her home or other
                                destination.
                            </b>
                        </p>
                    </li>
                    <li>
                        <p>
                            The ARV of the prize is $285,000.{' '}
                            <b>Please see Section 8 for the tax consequences of winning the prize.</b>
                            The winner is responsible for paying any costs and expenses not specified above. Any details
                            not specified above will be determined by Sponsor, in its sole discretion. The vehicle may
                            not be transferred prior to award. The winner may not request cash or a substitute prize;
                            however, Sponsor reserves the right to substitute a prize with another prize of equal or
                            greater value if the prize is not available for any reason, as determined by Sponsor, in its
                            sole discretion.
                        </p>
                    </li>
                </ol>
                <li>
                    <p>
                        <b>Tax Matters Related to the Prize</b>
                    </p>
                </li>
                <ol type="a">
                    <li>
                        <p>
                            You agree that if you win the Contest, your right to receive the vehicle and check
                            referenced in Section 7(a), or any other substitute prizes referenced in Section 7(b) (if
                            applicable) (collectively, the “<b>Prize</b>”) is subject to you (i) providing Sponsor a
                            completed IRS Form W-9 (or W-8BEN if you are a nonresident for United States federal income
                            tax purposes); and (ii) executing any additional agreements, certificates, instruments or
                            documents upon Sponsor’s request from time to time. You agree that Sponsor will not be
                            required to deliver the Prize until you have satisfied all of your obligations under this
                            Section 8(a) to Sponsor’s satisfaction. In addition, you agree that if you fail to comply
                            with any conditions of this Section 8(a) after you receive the Prize, you will be required
                            to refund to Sponsor immediately the entire value of the Prize.
                        </p>
                    </li>
                    <li>
                        <p>
                            You agree that if you win the Contest, the value of the Prize will be taxable income to you
                            and you will be solely responsible for the payment of any and all taxes owed under
                            applicable law with respect to the value of the Prize on a timely basis. Sponsor will
                            withhold taxes on the value of the Prize to the extent required under applicable law. You
                            will be issued an IRS Form 1099 or IRS Form 1042-S, as applicable, reflecting the value of
                            the Prize. You agree not to report or treat the value of the Prize as a gift or in any other
                            non-taxable manner.
                        </p>
                    </li>
                </ol>
                <li>
                    <p>
                        <b>General Conditions:</b> In the event that the operation, security, or administration of the
                        Contest is impaired in any way, Sponsor may, in its sole discretion, either: (a) suspend the
                        Contest to address the impairment and then resume the Contest; or (b) award the prize based on
                        the Judging Criteria from among the eligible entries received up to the time of the impairment
                        (with or without reference to the public votes). Sponsor reserves the right, in its sole
                        discretion, to disqualify and seek damages from any individual who tampers with the operation of
                        the Contest, violates these Official Rules, or acts in a disruptive manner. In the event of a
                        dispute as to the owner of an Entry, the Entry will be deemed to be owned by the owner of the
                        social media account on which the Entry was posted. Sponsor’s failure to enforce any term of
                        these Official Rules shall not constitute a waiver of that provision. Proof of sending any
                        communication to Sponsor by mail shall not be deemed proof of receipt of that communication. The
                        Contest is subject to federal, state, and local laws and regulations and is void where
                        prohibited.
                    </p>
                </li>
                <li>
                    <p>
                        <b>Release and Limitations of Liability:</b> By entering, you agree to release and hold harmless
                        Sponsor, Sony Music Entertainment, TelevisaUnivision, Inc., Round Group Limited, and their
                        respective parents, subsidiaries, affiliates, and advertising agencies, and each of their
                        respective officers, directors, employees, and agents (collectively, the “
                        <b>Released Parties</b>”) from and against any claim or cause of action arising out of
                        participation in the Contest or receipt or use of any prize, including, but not limited to
                        (a)&nbsp;unauthorized human intervention in the Contest (including the voting process); (b)
                        technical errors related to computers, servers, providers, or telephone or network lines; (c)
                        printing errors; (d) lost, late, postage-due, misdirected, or undeliverable mail; (e) errors in
                        the administration of the Contest or the processing of Entries or votes; or (f) injury or damage
                        to persons or property. You further agree that in any cause of action, in no event shall the
                        Released Parties be liable for attorney’s fees or other legal costs. You waive the right to
                        claim any damages whatsoever, including, but not limited to, punitive, consequential, direct, or
                        indirect damages.
                    </p>
                </li>
                <li>
                    <p>
                        <b>Disputes:</b> You agree that any and all disputes, claims and causes of action arising out
                        of, or connected with, the Contest or any prize awarded shall be resolved individually, without
                        resort to any form of class action, and exclusively by the appropriate court located in Los
                        Angeles, CA. All issues and questions concerning the construction, validity, interpretation, and
                        enforceability of these Official Rules, your rights and obligations, or the rights and
                        obligations of the Sponsor in connection with the Contest, shall be governed by, and construed
                        in accordance with, the laws of California, without giving effect to any choice of law or
                        conflict of law rules (whether of California or any other jurisdiction), which would cause the
                        application of the laws of any jurisdiction other than California.
                    </p>
                </li>
                <li>
                    <p>
                        <b>Winner:</b> The winner will be identified on the Site after November 30, 2024.
                    </p>
                </li>
                <li>
                    <p>
                        <b>Sponsor:</b> The Contest is sponsored by Loba Marketing, 10960 Wilshire Blvd., 5th Floor, Los
                        Angeles, CA 90024. Sony Music Entertainment is not the Sponsor of this Contest.
                    </p>
                </li>
            </ol>
        </Content>
    );
};

export default OfficialRules;
