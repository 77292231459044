import { DetailedHTMLProps, HTMLAttributes, ReactNode } from 'react';
import styles from './Content.module.css';
import cn from 'classnames';

type Props = { children?: ReactNode } & DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

/**
 * Layout wrapper component to give width/positioning
 * @param children
 * @param props
 * @constructor
 */
const Content = ({ children, ...props }: Props) => {
    return (
        <div {...props} className={cn(styles.content, props.className)}>
            {children}
        </div>
    );
};

export default Content;
