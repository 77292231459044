import { forwardRef, useState } from 'react';
import Image from 'ui/Image/Image';
import styles from './VideoPostCover.module.css';
import cn from 'classnames';

type Props = Partial<{
    thumbnail: string | null;
    thumbnailLarge: string | null;
    dynamicThumbnail: string | null;
    className: string;
}>;

const VideoPostCover = forwardRef<HTMLImageElement, Props>(
    ({ className, thumbnailLarge, thumbnail, dynamicThumbnail }, ref) => {
        const [hasDynamicCoverLoaded, setHasDynamicCoverLoaded] = useState(false);

        return (
            <div className={cn(styles.container, className)}>
                <Image
                    ref={ref}
                    src={thumbnailLarge ?? ''}
                    srcSet={`${thumbnail} 220w, ${thumbnailLarge} 440w`}
                    sizes="(min-width: 744px) 220px, 180px"
                    alt=""
                />

                <img
                    className={styles.dynamicCover}
                    src={dynamicThumbnail ?? ''}
                    alt=""
                    data-loaded={hasDynamicCoverLoaded}
                    onLoad={() => {
                        setHasDynamicCoverLoaded(true);
                    }}
                    loading="lazy"
                />
            </div>
        );
    }
);

export default VideoPostCover;
