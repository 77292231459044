import { useCookies } from 'react-cookie';

type ConsentLevel = 'all' | 'essential' | 'none';

export function useConsentCookie() {
    const [{ cookieConsent }, setCookie] = useCookies<
        'cookieConsent',
        {
            cookieConsent?: ConsentLevel;
        }
    >(['cookieConsent']);

    const handleSetConsentCookie = (value: ConsentLevel) => {
        setCookie('cookieConsent', value);
    };

    return [cookieConsent, handleSetConsentCookie] as const;
}
